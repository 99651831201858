const fontStack = `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`

export const fontDefault = `"Montserrat", ${fontStack}`

export const fonts = {
  base: `100%`,
  pSM: `1rem`,
  pMD: `1.125rem`,
  pLineHeight: `1.625`,

  h1XS: `1.75rem`,
  h1SM: `2rem`,
  h1MD: `3.1875rem`,
  h1LineHeight: `1.25`,
  h1Space: `-0.02em`,

  h2XS: `1.3125rem`,
  h2SM: `1.4375rem`,
  h2MD: `2.25rem`,
  h2LineHeight: `1.391`,
  h2Space: `-0.015em`,

  h3SM: `1rem`,
  h3MD: `1.125rem`,
  h3LineHeight: `1.391`,
  h3Space: `-0.01em`,

  h4SM: `.625rem`,
  h4MD: `.75rem`,
  h4LineHeight: `1.6`,
  h4Space: `.02em`,

  metaSM: `.75rem`,
  metaMD: `.875rem`,
  metaLineHeight: `1.334`,
  metaSpace: `.017em`,

  txtUp: `uppercase`,

  reg: `400`,
  semi: `600`,
  bold: `700`,
}

export const colors = {
  portland: `rgba(217, 217, 225, 1)`, //#D9D9E1
  asphalt: `rgba(18, 18, 18, 1)`, //#121212
  limestone: `rgba(158, 158, 166, 1)`, //#9E9EA6
  limestone12: `rgba(158, 158, 166, 0.12)`, //#9E9EA6
  limestone30: `rgba(158, 158, 166, 0.3)`, //#9E9EA6
  limestone60: `rgba(158, 158, 166, 0.6)`, //#9E9EA6
  sand: `rgba(251, 207, 35, 1)`, //#FBCF23
  redrock: `rgba(219, 72, 111, 1)`, //##DB486F
  space: `rgba(0, 184, 184, 1)` //#00B8B8
}

export const media = {
  xs: `(min-width: 22rem)`, //352px
  sm: `(min-width: 30rem)`, //480px
  md: `(min-width: 45rem)`, //720px
  lg: `(min-width: 60rem)`, //960px
  xl: `(min-width: 75rem)`, //1200px
}

export const spaces = {
  smNM: `calc((((100vw - ((1rem * 3) + (1.5rem * 2))) / 4) * 1) + ((1rem * 1) + 1.5rem))`, //SM4G2416-1NM
  sm2: `calc((((100vw - ((1rem * 7) + (1.5rem * 2))) / 8) * 2) + (1rem * 2))`, //SM8G2416-2
  sm4: `calc((((100vw - ((1rem * 7) + (1.5rem * 2))) / 8) * 4) + (1rem * 4))`, //SM8G2416-4
  md4NM: `calc((((100vw - ((1rem * 11) + (2.5rem * 2))) / 12) * 4) + ((1rem * 4) + 2.5rem))`, //MD12G4016-4NM
  md4: `calc((((100vw - ((1rem * 11) + (2.5rem * 2))) / 12) * 4) + (1rem * 4))`, //MD12G4016-4
  md6: `calc((((100vw - ((1rem * 11) + (2.5rem * 2))) / 12) * 6) + (1rem * 6))`, //MD12G4016-6
  md8: `calc((((100vw - ((1rem * 11) + (2.5rem * 2))) / 12) * 8) + (1rem * 8))`, //MD12G4016-8
  lg4NM: `calc((((100vw - ((1rem * 11) + (3.5rem * 2))) / 12) * 4) + ((1rem * 4) + 3.5rem))`, //LG12G5616-4NM
  lg4: `calc((((100vw - ((1rem * 11) + (3.5rem * 2))) / 12) * 4) + (1rem * 4))`, //LG12G5616-4
  lg6: `calc((((100vw - ((1rem * 11) + (3.5rem * 2))) / 12) * 6) + (1rem * 6))`, //LG12G5616-4
  lg8: `calc((((100vw - ((1rem * 11) + (3.5rem * 2))) / 12) * 8) + (1rem * 8))`, //LG12G5616-4
}
