import React from "react"
import posed, { PoseGroup } from "react-pose"
import styled from "styled-components"

import { colors } from "../utils/tokens"

const Container = posed.div({
  enter: {
    position: `absolute`,
    width: "100%",
    height: "100%",
    opacity: 1,
    transition: {
      opacity: { ease: "easeIn", duration: 1000, delay: 0 },
    },
    afterChildren: true,
  },

  exit: {
    position: `absolute`,
    width: "100%",
    height: "100%",
    opacity: 0,
    transition: {
      opacity: { ease: "easeOut", duration: 1000, delay: 0 },
    },
  },
})

const Indicator = posed.div({
  enter: {
    top: `100%`,
    opacity: 0,
    y: `100%`,
    height: 0,
    transition: {
      y: { ease: "easeIn", duration: 500, delay: 0 },
      top: { ease: "easeIn", duration: 500, delay: 0 },
      opacity: { ease: "easeIn", duration: 250, delay: 0 },
      height: { ease: "easeIn", duration: 0, delay: 0 },
    },
  },
  exit: {
    top: `-100%`,
    opacity: 0.3,
    y: `-100%`,
    height: `200%`,
    transition: {
      y: { ease: "easeOut", duration: 1000, delay: 250 },
      top: { ease: "easeOut", duration: 1000, delay: 250 },
      opacity: { ease: "easeOut", duration: 1000, delay: 250 },
      height: { ease: "easeOut", duration: 1000, delay: 250 },
    },
  },
})

const Cover = styled(Indicator)`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 18;
  background: ${colors.portland};
`

class PageTransition extends React.PureComponent {
  render() {
    const { children, location } = this.props
    return (
      <PoseGroup>
        <Container key={location.pathname}>
          {children}
          <Cover key="cover" />
        </Container>
      </PoseGroup>
    )
  }
}

export default PageTransition